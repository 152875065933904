import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/bodycomponents/contact-form"
import FAQ from "../../components/faq-components/faq"
import { logisticsFaqItems } from "../../utils/data/faq"
import TopHeroComponent from "../../components/herocomponents/topherocomponent"
import R_BLUE_BG from "../../images/background-images/bg-royal-blue.svg"
import M_GRAY_BG from "../../images/background-images/bg-medium-gray.svg"
import { colors } from "../../utils/colors"
import CardComponent from "../../components/bodycomponents/card-component"

const LogisticsPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Lab Equipment Logistic Services: Crating, Storage & Freight`}
          description={`At Surplus Solutions, we simplify equipment purchases with our trusted network for safe handling and customized crating, skidding, and freight services.`}
        />
        <TopHeroComponent
          backgroundImage={R_BLUE_BG}
          backgroundColor={colors.blue}
          shadowColor="shadow-royalBlue-bottom"
          fontShadowColor={colors.royalBlue}
          pageTitle="Logistics Services"
          title="Laboratory Equipment Logistics Made Easy"
          body={
            <p className="pt-4">
              Surplus Solutions streamlines laboratory equipment logistics,
              managing every step with trusted partners for secure, on-time
              transportation. Whether local or international, we handle the
              details so you can focus on your business and resume operations
              faster.
            </p>
          }
        />
        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl py-14">
            <div className="text-left">
              <h2 className="pb-2">
                Comprehensive Lab Equipment Moving Services
              </h2>
              <h3>Pre-Moving Planning & Consulting</h3>
              <p className="pb-6">
                Pre-move planning and consulting set Surplus Solutions’ lab
                equipment logistics services apart, saving you the trouble of
                coordinating a complex and specialized move. From scheduling to
                equipment safety, our expert team handles all the details,
                implementing a tailored plan designed around your schedule,
                budget, and other requirements for a safe and seamless move.
              </p>
              <h3>Rigging</h3>
              <p className="pb-6">
                Surplus Solutions collaborates only with companies that provide
                certified rigging services and have a track record of following
                all safety and inspection procedures. Whether you’re
                transferring heavy machinery or a delicate piece of used lab
                equipment, rest assured your assets will be lifted, loaded,
                positioned, and moved with utmost care and precision.
              </p>
              <h3>Packaging & Crating</h3>
              <p className="pb-6">
                Surplus Solutions’ experts use the best and most appropriate
                packaging materials to ensure your assets are fully protected
                from damage through every step of the move. No matter the size
                of your equipment, our team provides a customized crating
                solution so even the most delicate pieces arrive in pristine
                condition.
              </p>
              <h3>Storage</h3>
              <p className="pb-6">
                When you take advantage of Surplus Solution’s logistics
                services, you gain access to secure and affordable storage
                options. Instead of being dependent on expensive storage
                vendors, you can store your equipment safely at our dedicated
                storage facilities in Woonsocket, RI, Murrieta, CA, and
                Vandenheim, France at a fraction of the cost. We partner with
                trusted facilities in other regions, so wherever you go, we’ve
                got you covered.
              </p>
              <h3>Transporting</h3>
              <p className="">
                With a full range of transportation solutions—including trucks,
                boats, rail, and air—Surplus Solutions ensures a smooth,
                efficient move in town, across the United States or around the
                world. We make sure there’s no language barrier when it comes to
                safe, timely, and proper delivery.
              </p>
            </div>
          </div>
        </div>
        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl py-10">
            <h2 className="text-center pb-6">Why Choose Surplus Solutions?</h2>
            <div className="grid gap-9 lg:grid-cols-3">
              <CardComponent
                title={
                  <>
                    Comprehensive <br /> Logistics Services
                  </>
                }
                body={
                  <p>
                    We are an industry leader, so we know all the ins and outs
                    of completing a successful move. The Surplus Solutions team
                    manages every detail to make your transfer smooth and
                    effortless
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
              <CardComponent
                title="Secure Equipment Delivery"
                body={
                  <p>
                    We are an industry leader, so we know all the ins and outs
                    of completing a successful move. The Surplus Solutions team
                    manages every detail to make your transfer smooth and
                    effortless.
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
              <CardComponent
                title="A Network of Top Experts"
                body={
                  <p>
                    We’ve built a network of professionals trained and fully
                    equipped to move all types of equipment, locally,
                    nationally, and internationally. Surplus Solutions
                    understands what’s at stake; we make sure everyone involved
                    has the experience to do it right.{" "}
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
            </div>
          </div>
        </div>

        <FAQ
          title="Logistics FAQs"
          items={logisticsFaqItems}
          backgroundColor={colors.lightGray}
          backgroundImage={M_GRAY_BG}
          shadowColor="shadow-mediumGray-top"
        />
        <ContactForm
          title="Ready to simplify your lab equipment move?"
          body={
            <p>
              Contact us today to start planning your seamless, stress-free
              relocation!
            </p>
          }
          backgroundColor={colors.containerGray}
          fontShadowColor={colors.royalBlue}
          shadow="shadow-royalBlue-bottom"
          formType="Contact"
        />
      </Layout>
    </>
  )
}

export default LogisticsPage
