import React from "react"

export default function CardComponent(props) {
  return (
    <div className="mb-9">
      <div
        className={`rounded-[15px] ${props.padding ? props.padding : 'p-[35px]'} text-center flex flex-col justify-between h-full ${props.shadow}`}
        style={{ backgroundColor: props.backgroundColor }}
      >
        <h3 className="mb-4 text-[24px]">{props.title}</h3>
        <div className="flex-grow flex items-start justify-center">
          <span>{props.body}</span>
        </div>
      </div>
    </div>
  )
}
